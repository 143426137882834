
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {Action, Getter, State} from "vuex-class";
import {INotificationDB, IUser, StoreAction} from "@/types";
import moment from "moment-timezone";
import ViewConfigurator from "@/components/ViewConfigurator.vue";
import Icon from "@/components/Icon.vue";
import {capitalize} from "@/helpers/commons";
import {mixins} from "vue-class-component";
import PageMixin from "@/mixins/PageMixin.vue";
import { ROUTES } from '@/data';
import PaginationServer from "@/components/PaginationServer.vue";

    @Component({
        name: "Notifications",
        components: {ViewConfigurator, PaginationServer}
    })
    export default class Notifications extends mixins(PageMixin) {
        @Action('actions/loadNotifications') loadNotifications!: StoreAction;
        @Action('actions/readNotifications') readNotifications!: StoreAction;
        @Action('actions/getUserNotifications') getUserNotifications!: StoreAction;

        @Getter('isAgency') isAgency!: boolean;
        @State('selectedCollaborators') selectedCollaborators!: IUser[];

        loading = false;
        globalErrors:any = [];
        notifications: INotificationDB[] = [];
        viewFilters:any = {
            level: "",
            type: "",
            client: "",
            agency: "",
            interim: ""
        };
        pagination: any = {
            currentPage: 1,
            offset: 0,
            limit: 50,
            total: 0
        }
        timeout:any = 0;
        totalRecords = 0;
        filters: any = {};

        @Watch('selectedCollaborators', {deep: true})
        onCollaborators() {
            this.timedInit();
        }

        @Watch('viewFilters', {deep: true})
        onFilters() {
            this.timedInit();
        }

        get headers() {
            return [
                {
                    field: "_id",
                    hidden: true,
                },
                // {
                //     field: "type",
                //     label: this.$t('page.notifications.columns.type'),
                //     formatFn: (value:string) => this.$t('page.notifications.type.' + value),
                //     sortable: false,
                //     filterOptions: {
                //         enabled: false
                //     }
                // },
                {
                    field: "createdAt",
                    label: this.$t('page.notifications.columns.timestamp'),
                    formatFn: (value: number) => capitalize(moment(value).calendar()),
                    sortable: false,
                    filterOptions: {
                        enabled: false
                    }
                },
                {
                    field: "level",
                    label: this.$t('page.notifications.columns.level'),
                    sortable: false,
                    filterOptions: {
                        enabled: false
                    }
                },
                {
                    field: "title",
                    label: this.$t('page.notifications.columns.title'),
                    html: true,
                    sortable: false,
                    filterOptions: {
                        enabled: false
                    }
                },
                {
                    field: "text",
                    label: this.$t('page.notifications.columns.text'),
                    html: true,
                    sortable: false,
                    filterOptions: {
                        enabled: false
                    }
                },
                {
                    field: "status",
                    formatFn: (value:string) => this.$t('page.notifications.status.' + value),
                    label: this.$t('page.notifications.columns.status'),
                    sortable: false,
                    filterOptions: {
                        enabled: false
                    }
                },
                // {
                //     field: "relations",
                //     label: this.$t('page.notifications.columns.relations'),
                //     sortable: false,
                //     filterOptions: {
                //         enabled: false
                //     }
                // },
                // {
                //     field: "terminationTimestamp",
                //     label: this.$t('page.notifications.columns.end'),
                //     formatFn: (value: number) => moment(value).format(<string>this.$t('date.format')),
                //     sortable: false,
                //     filterOptions: {
                //         enabled: false
                //     }
                // },
                // {
                //     field: "collaborators",
                //     label: this.$t('page.notifications.columns.collaborators'),
                //     sortable: false,
                //     filterOptions: {
                //         enabled: false
                //     }
                // },
                {
                    field: "link",
                    hidden: true
                }
            ];
        }

        get rows() {
            return this.notifications;
        }

        timedInit() {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => {
                this.init();
            }, 300);
        }

        lineClicked(row:any) {
            const route = row?.row.link;

            if (!route) return;

            const isInterimRoute = ['interim-registration', "interim-leave-agency", "worker"].includes(route.name);
            if (isInterimRoute) {
                return this.$router.push({
                    name: ROUTES.APP.INTERIM._ROOT,
                    params: {interimId: route.params.workerId || route.params.interimId}
                });
            }

            this.$router.push(route);
        }

        onModifier(modifiers:any) {
            console.log(modifiers)
            modifiers.currentPerPage = modifiers.currentPerPage || 50;
            console.log(modifiers.currentPerPage || 50);
            this.pagination.offset = (modifiers.currentPage - 1) * modifiers.currentPerPage;
            this.pagination.limit = modifiers.currentPerPage;
            this.pagination.currentPage = modifiers.currentPage;
            this.init(modifiers);
        }

        async init(modifiers?: any) {
            this.loading = true;
            try {
                this.filters = {
                    type: this.viewFilters.notificationType,
                    level: this.viewFilters.notificationLevel,
                    client: this.viewFilters.client,
                    worker: this.viewFilters.interim,
                    offset: this.pagination.offset,
                    limit: this.pagination.limit,
                    read: true,
                    currentPage: this.pagination.currentPage,
                };
                console.log(this.pagination.current)

                if (!this.isAgency) {
                    delete this.filters.client;
                    this.filters.agency = this.viewFilters.agency;
                }
                const result:any = await this.loadNotifications(this.filters);

                this.notifications = result.notifications;
                this.totalRecords = result.pagination.total;

                let p: any = [];

                this.notifications.forEach((n:any) => {
                    if (n.status === "unread") {
                        n.status = "read";
                        p.push(this.readNotifications(n._id));
                    }
                });

                if (p.length) {
                    await Promise.all(p);
                }
                await this.getUserNotifications();
            } catch (e) {
                console.log(e);
            }
            this.loading = false;
        }

        beforeMount() {
            this.init();
        }
    }
